<!--服务预约-->
<template>
    <div class="serviceAppointments" :style="serviceAppointments">
        <common-nav-bar title="选择服务中心"></common-nav-bar>
        <van-cell-group inset class="m0" style="margin-top: 56px">
            <van-cell class="borderBottom">
                <template #title>
                    <span class="tplMinor">城市：</span>
                    <span class="tplMain" @click="showCityPopup = true">{{ curCity }}</span>
                    <van-icon name="arrow-down"></van-icon>
                </template>
                <template #default>
                    <div>
                        <span class="bookingEmpty">选择服务中心</span>
                    </div>
                </template>
            </van-cell>
        </van-cell-group>

        <div class="patientInfo">
            <van-cell-group inset class="mtb10 bgNo">
                <van-cell
                    v-for="(item, i) in clinics"
                    :key="i"
                    :class="
                        i === chooseClinicIndex
                            ? 'borderDefault mb10 chooseBox'
                            : 'borderDefault mb10'
                    "
                    :title="item.name"
                    title-class="tplTitle"
                    @click="chooseClinic(i)"
                >
                    <template #label>
                        <van-icon name="location" class=""></van-icon>
                        <span v-if="!item.distance" class="tplTextDefault">计算距离中... </span>
                        <span v-if="item.distance" class="tplTextDefault"
                            >距您{{ item.distance }}km
                        </span>
                        <span class="tplTextDefault">{{ item.address }} </span>
                        <span class="guidance" @click="openLocation(item)"><van-icon name="guide-o" />去这里</span>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
        <div class="confirmButton">
            <van-cell-group inset class="mtb10 bgNo">
                <van-cell class="textC bgNo" size="lager">
                    <template #title>
                        <van-button
                            class="newBook plr40"
                            color="#4AB8AB"
                            size="normal"
                            @click="confirmChooseClinic"
                            >确定
                        </van-button>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
        <van-popup v-model="showCityPopup" position="bottom">
            <van-picker
                show-toolbar
                :columns="cities"
                :default-index="cities.findIndex(v => v === curCity)"
                @confirm="pickCity"
                @cancel="showCityPopup = false"
            />
        </van-popup>
    </div>
</template>

<script>
import { getClinicCities, getClinics } from '@/services/clinic';
import { calculateDistance, toast } from '@/util';
import CommonNavBar from '@/components/common-nav-bar';
import locationMixin from '../../../mixins/locationMixin';
import wx from 'weixin-js-sdk';

export default {
    name: 'clinic-reservation',
    components: { CommonNavBar },
    mixins:[locationMixin],
    data() {
        return {
            curSn: '',
            curSnUser: {},
            cities: [],
            clinics: [],
            curCity: '',
            curClinic: {},
            showCityPopup: false,
            showClinicPopup: false,
            chooseClinicIndex: 0,
            clinicWeeks: [],
            selectP: '',
            serviceAppointments: {
                backgroundImage: 'url(' + require('@/assets/images/myBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
            },
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            userPhoto: require('@/assets/images/userPhoto.png'),
        };
    },
    async created() {
        this.curSn = localStorage.getItem('curSn');
        if (this.curSn) {
            this.curSnUser = JSON.parse(localStorage.getItem('curSnUser'));
        }
        await this.getCities();
    },
    methods: {
        async getCities() {
            const { code, message, data } = await getClinicCities();
            if (!code) {
                return toast(message);
            }
            this.cities = data.filter(d => d);
            this.curCity = this.$route.query.city ? this.$route.query.city : this.cities[0];
            await this.getClinics();
        },
        async getClinics() {
            const { code, message, data } = await getClinics({ city: this.curCity });
            if (!code) {
                return toast(message);
            }
            if (data.length) {
                this.clinics = data;
                this.curClinic =
                    this.$route.query.clinicId && this.$route.query.city === this.curCity
                        ? this.clinics.filter(c => String(c.id) === this.$route.query.clinicId)[0]
                        : data[0];
                this.chooseClinicIndex =
                    this.$route.query.clinicId && this.$route.query.city === this.curCity
                        ? this.clinics.findIndex(c => String(c.id) === this.$route.query.clinicId)
                        : 0;
            }
            if (this.$store.state.location) {
                this.calcDistance();
            } else {
                let location = await this.getLocation();
                this.$store.commit('updateLocation', location);
                this.calcDistance();
            }
        },
        async pickCity(val) {
            this.curCity = val;
            await this.getClinics();
            this.showCityPopup = false;
        },
        chooseClinic(i) {
            this.chooseClinicIndex = i;
        },
        async confirmChooseClinic() {
            this.curClinic = this.clinics[this.chooseClinicIndex];
            this.$route.params.selectedCity = this.curCity;
            this.$route.params.selectedClinic = JSON.stringify(this.curClinic);
            this.$router.back();
        },
        calcDistance() {
            this.clinics.forEach(r => {
                let { longitude, latitude } = this.$store.state.location;
                const distance = calculateDistance(longitude, latitude, r.lng, r.lat);
                if (distance) this.$set(r, 'distance', distance);
            });
        },
        openLocation(clinic) {
            const { name, address, lng, lat } = clinic;
            if (!lng || !lat) {
                return toast('诊所位置信息缺失，无法导航');
            }
            wx.openLocation({
                latitude: lat, // 纬度，浮点数，范围为90 ~ -90
                longitude: lng, // 经度，浮点数，范围为180 ~ -180。
                name, // 位置名
                address, // 地址详情说明
                scale: 28, // 地图缩放级别,整型值,范围从1~28。默认为最大
                infoUrl: '', // 在查看位置界面底部显示的超链接,可点击跳转
            });
        },
    },
};
</script>

<style scoped>
.serviceAppointments {
    padding: 10px;
    box-sizing: border-box;
}

.bookCard {
    padding: 10px 0;
}

.borderBottom.van-cell::after {
    border-bottom: 1px solid #bebebe;
}

.bookCard .van-cell {
    font-size: 12px;
    padding: 8px 16px;
}

.tplMinor {
    color: #acacac;
}

.tplMain {
    color: #282a2d;
}

.bookingEmpty {
    color: #989898;
}

.BookingHave {
    color: #4ab8ab;
}

.newBook {
    height: 35px;
}

.tplButton {
    font-size: 12px;
    border: none;
    text-decoration: underline;
}

.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
    padding: 0 15px;
}

.instrumentCard {
    background: #fff;
    margin: 10px 16px;
    overflow: hidden;
    border-radius: 8px;
}

.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.cardTileLeft {
    position: relative;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #4ab8ab;
}

.patientInfo {
    background: #f6f6f8;
    border-radius: 10px;
    overflow-y: auto;
    margin-top: 10px;
    max-height: calc(100% - 175px);
}

.borderDefault.chooseBox {
    border: 1px solid #00c3ae;
    overflow: hidden;
    border-radius: 8px;
}

.tplSerial {
    padding-right: 10px;
}

.tplSerial,
.tplTitle {
    font-size: 14px;
    font-weight: 600;
    color: #282a2d;
}

.tplTextDefault {
    font-size: 12px;
    margin-left: 5px;
    color: #727272;
    font-weight: 400;
}

.chooseBox .tplSerial,
.chooseBox .tplTitle {
    color: #00c3ae;
}

.timeChoose .van-tabs__nav {
    background: none;
}

.tplTextEmpty {
    font-size: 18px;
    color: #acacac;
    font-weight: 600;
}

.timeChoose {
    padding: 10px 0 0 0;
}

.timeChooseTitle {
    color: #282a2d;
    font-size: 15px;
    margin-bottom: 10px;
    padding: 10px 15px 0px;
    font-weight: 600;
}

.timeChoose .van-tab {
    font-size: 12px;
}

.timeChoose .van-tabs__nav--line.van-tabs__nav--complete {
    padding-top: 10px;
}

.timeChoose .van-tabs__nav {
    background: none;
}

.timeChoose .van-tabs__content {
    margin-top: 10px;
}

.timeChoose .van-tabs__wrap {
    padding: 0 15px;
}

.timeChoose .chooseBody {
    padding: 10px 15px;
    background-color: #fff;
    overflow: hidden;
}

.timeChoose .van-cell {
    padding: 8px 16px;
    border: 1px solid #979797;
    border-radius: 5px;
    margin: 5px 0;
}

.timeChoose .van-cell__title {
    font-size: 12px;
    color: #282a2d;
}

.radioText {
    color: #4ab8ab;
}

.radionNo {
    color: #888888;
}

.img-icon {
    width: 16px;
}

.flexAuto .van-cell__title,
.flexAuto .van-cell__value {
    flex: auto;
}

.events {
    background: #fff;
    margin: 10px 16px;
    border-radius: 10px;
    overflow: hidden;
}

.events .van-card__header {
    display: block;
}

.eventsState {
    padding: 10px 0;
    border-bottom: 1px solid #ebedf0;
    margin: 0 10px;
}

.events .van-card__title {
    font-size: 15px;
    color: #282a2d;
    line-height: 35px;
}

.events .van-cell {
    padding: 0;
    font-size: 12px;
    color: #6d7278;
    line-height: 24px;
}

.events .van-card__thumb img {
    border-radius: 0;
}

.confirmButton {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.van-nav-bar {
    width: calc(100% - 20px);
}
.guidance{
    font-size: 12px;
    color: #444;
    font-weight: bold;
    padding: 0 5px;
}
</style>
